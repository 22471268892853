import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/CompleteRegisterPage.css';
import '../Styles/AdvicePage.css';
import completeIcon from '../assets/successReg-img.png';

const AdvicePage = () => {
    const { setStep, userData, setUserData } = useContext(multiStepContext);

    const [showSwitchPopup, setShowSwitchPopup] = useState(false)
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [popupOption, setPopupOption] = useState('');


    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const showConfirmSwitch = () => {
        setShowSwitchPopup(true)
    };

    const hideConfirmSwitch = () => {
        setShowSwitchPopup(false) 
    }

    const confirmSwitch = () => {
        if(popupOption == 'confirm'){
            setUserData({ ...userData, phoneNumberOption: "ใช่" });
            setStep(5)
        }else{
            alert('กรุณายืนยันรับซิมการ์ดใหม่เบอร์ใหม่')
        }
    }

    return (
        <div className="complete-container">
            <div className='complete-regis'>
                คำแนะนำ
                <br />
                <span style={{ fontSize: '16' }}>Advice</span>
            </div>
            <div className="complete-img-container">
                <img src={completeIcon} className={`complete-reg-img ${windowHeight < 650 ? 'hidden-notFound' : 'complete-reg-img'}`}></img>
            </div>
            <div className='advice-container'>
                <strong>ย้ายค่ายเบอร์เดิม</strong>
                <ol className='advice-text'>
                    <li>
                        กด * 151 * เลขบัตรประชาชน # โทรออก เพื่อรับรหัสยืนยันจากค่ายเดิม
                    </li>
                    <li>
                        ชำระยอดคงค้างของค่ายเดิม ให้เรียบร้อย
                    </li>
                    <li>
                        ส่งรหัสยืนยันที่ได้รับทางSMS จากค่ายเดิม + สำเนาบัตรประชาชนเจ้าของเลขหมาย ส่งทางไลน์ Line : @netfree_infinite  หรือกดส่งตามลิ้งค์นี้ได้เลยครับ
                        <span style={{ marginLeft: '5px' }}>
                            <a href="https://lin.ee/Z0EMZL0h" target="_blank" rel="noopener noreferrer" className="popup-link">
                                https://lin.ee/Z0EMZL0h
                            </a>
                        </span>
                    </li>
                    <li>
                        รอการอนุมัติการย้ายค่าย และนำหมายเลขเดิม กลับมาลงทะเบียนขอรับสิทธิ์
                    </li>
                    <li>
                        เมื่อได้รับการอนุมัติแล้ว ทางบริษัทฯ จะเริ่มจัดส่ง ตั้งแต่วันที่ 15 มกราคม 2568 เป็นต้นไป
                    </li>
                </ol>
            </div>
            <div className="advice-button-section">
                <button onClick={() => showConfirmSwitch()} className="form-switch-bt">
                    ต้องการเปลี่ยนเป็น รับซิมการ์ดใหม่เบอร์ใหม่
                </button>
                <button onClick={() => {
                    setStep(1)
                    setUserData(null)
                }}
                    className="form-switch-back-bt">
                    กลับสู่หน้าหลัก
                </button>
            </div>

            {showSwitchPopup && (
                <div className="popup-overlay">
                <div className="popup-window">
                    <div className="popup-header">
                    <span className="popup-title">ยืนยันรับซิมการ์ดใหม่เบอร์ใหม่</span>
                    </div>
                    <div className="popup-content">
                    <div className="popup-select">
                        <input
                        type="checkbox"
                        id="option1"
                        value="option1"
                        onChange={() => setPopupOption('confirm')}
                        />
                        <label htmlFor="option1"> ยืนยันรับซิมการ์ดใหม่เบอร์ใหม่</label>
                    </div>
                    <div className="popup-buttons">
                        <button className="popup-cancel" onClick={() => {
                        hideConfirmSwitch()
                        setPopupOption('')
                        }}>
                        ยกเลิก
                        </button>
                        <button className="popup-accept" onClick={() => confirmSwitch()
                        }> 
                        ตกลง
                        </button>
                    </div>
                    </div>
                </div>
                </div>
            )}
        </div>
        
    )
}

export default AdvicePage